@import "./../../../styles/style.scss";

.add-delete-container {
  display: flex;
  width: 60%;
  margin: 0 auto;
}

.add-delete-container-date {
  padding-left: 4px;
  padding-right: 4px;
}

.add-delete-button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: $blue;
  outline: none;
  font-size: $standard-font-size;
}

.left-align-header {
  margin-left: 0;
}
