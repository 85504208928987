@import "./../../../../styles/style.scss";

.preview-modal {
  overflow: inherit;
}
.preview-filters {
  min-width: 600px;
}

.preview-controls-header {
  display: flex;
}

.preview-control-date {
  width: 30%;
  padding-right: 50px;
}
.preview-control-Rt {
  width: 30%;
  padding-right: 50px;
}
.preview-control-time {
  width: 30%;
}

.preview-select {
  position: relative;
  padding: $standard-padding;
}

.view-button {
  overflow: hidden;
}

.preview-grid {
  max-height: 400px;
}

.footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: $standard-padding;
}

.close-button {
  padding: $standard-padding;
}
