@import "./../../../styles/style.scss";

.recurrence-container {
  display: flex;
}
.radio-controls {
  padding: $standard-padding;
  padding-right: 70px;
}
.separator-container {
  height: auto;
}

.separator {
  height: 150px;
}
.separator::after {
  background-color: black;
}

.selected-control-container {
  padding: $standard-padding;
}

.flex-box {
  display: flex;
}

.control-style {
  padding-left: $standard-padding;
  padding-right: $standard-padding;
}

.center-box {
  margin-top: 30px;
}

.daily-box {
  display: flex;
  border: 1px;
}

.selected-Dates-Box {
  padding-left: 16px;
}
.selected-Dates {
  //border-style: solid;
  height: 100px;
  width: 150px;
  overflow: auto;
}

.saved-rule {
  display: flex;
  padding-bottom: 20px;
}
.selected-Dates-list {
  list-style-type: none;
  padding: 4px;
  margin: 0px;
}
