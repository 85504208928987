@import "./../../../styles/style.scss";

.rule-modal {
  width: 550px;
  
}
.ms-ComboBox-callout {
  //.ms-ComboBox-optionsContainer{
  width: 400px;
}
.saved-rule {
  display: flex;
  padding-bottom: 20px;
}

.saved-rules-dropdown {
  width: 450px;
  margin-right: 20px;
}

.saved-rules-apply-button {
  margin-top: 28px;
}

#start-time-info {
  margin-top: 3px;
  margin-right: 5px;
}
#start-time-text {
  margin-right: 20px;
}
#rule-start-time {
  margin-right: 10px;
}

// [id^="date-range-"] {
//   margin-right: 10px;
// }

.rule-buttons {
  margin: 8px;
}
#date-range * {
  margin-right: 9px;
}
