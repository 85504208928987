@import "./../../../styles/style.scss";

.run-schedule-grid {
  height: 60vh;
  position: relative;
}

.run-schedule-grid-header {
  .ms-DetailsHeader {
    height: 100px;
    z-index: 10;
    padding: 0px;
  }
}

.run-schedule-grid {
  .ms-Checkbox {
    margin-top: 2px;
  }
}

.time-zone-message {
  font-size: 12px;
}

.run-drilldown-nav-bar {
  font-size: 12px;
  color: #006cff;
}

.run-drilldown-template-name {
  padding-left: 10px;
}

.run-schedule-update-buttons {
  display: inline-flex;
}

.ms-DetailsHeader {
  border-bottom: 1px solid darkgray;
}

.ms-DetailsRow-fields {
  background: inherit;
}

.ms-DetailsRow-cell[data-automation-key="recurrenceRule"],
.ms-DetailsHeader-cell[data-item-key="recurrenceRule"] {
  background-color: inherit;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  height: 100%;
}

.ms-DetailsRow-cell[data-automation-key="processName"],
.ms-DetailsHeader-cell[data-item-key="processName"] {
  background-color: inherit;
  position: -webkit-sticky;
  position: sticky;
  left: 44px;
  z-index: 2;
  border-right: 1px solid darkgray;
  height: 100%;
  margin-right: 44px;
}
