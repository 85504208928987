@import "./../../../styles/style.scss";

.filter {
  padding-top: 10px;
  display: flex;
  padding-bottom: 32px;
}

.expand-icon {
  margin-top: 16px;
}

.icons-stack {
  display: flex;
}

.filter-size {
  width: 100%;
}

.filter {
  fieldset {
    border-style: solid;
    border-radius: 16px;
  }
  legend {
    background-color: rgb(35, 31, 32);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
  }
}

.rule {
  width: 10%;
}
.view-options {
  width: 20%;
}
.view-controls {
  margin-top: 10px;
  display: flex;
}

.run-schedule-toggle {
  width: 120px;
  margin-top: 4px;
}
.weekly {
  display: flex;
  margin-top: 18px;
}

.week-range {
  margin-top: 4px;
  width: 60px;
  text-align: center;
  font-weight: bold;
}
