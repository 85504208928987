@import "./../side-nav-bar-style-variables.scss";

.side-nav-button {
  background-color: $sideNavColor;
  border: none;
  color: $text-color;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: Transparent;
  cursor: pointer;
  border-radius: 0px;
  padding: 0px;
  padding-left: $standard-padding;
  font-size: 14px;
  font-family: sans-serif;
  margin-top: 0px;
 // outline: none;
  text-decoration: none;
}

.side-nav-button-link {
  width: 100%;
  height: $image-dimension;
  margin-top: $standard-padding;
  display: inline-block;
  background-color: Transparent;
  text-decoration: none;
  color: $text-color;
}

.parent-expand-collapse-icon {
  float: right;
  .ms-Button {
    background-color: transparent;
    color: white !important;
  }
}

.nav-node-name {
  width: 90%;
  text-align: left;
  //outline: none;
}

.side-nav-button  :focus-visible{
  color: white;
  background-color: black;
  text-decoration: none
}