.info-item {
  float: left;
  width: 33%;
  // margin-top: 10px;
}

.label {
  float: left;
  width: 25%;
  padding-right: 10px;
}

.text-field {
  float: left;
  width: 70%;
}
