.new-connection{
    width: 600px;
    //height: 500px;
}

.validation-warning-banner{
    margin-bottom: 10px;
    color: #e50000 ;
}

.new-connection-buttons-list{
    text-align: end;
    //width: 50%;
}

.new-connection-button{
    margin-left: 10px;
    border-radius: 20px;
    background-color: #288560;
    border-color: #45CCB1;
}

.new-connection-dropdown{
    margin-top: 15px;
}

.header-buttons {
    margin-left: 10px;
    border-radius: 20px;
    background-color: #288560;
    border-color: #45CCB1;
    height: 25px;
    font-size: 13px;
}