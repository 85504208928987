@import "./../../../styles/style.scss";

$header-background: white;

.header {
  display: flex;
  height: $image-dimension;
  padding: $standard-padding;
  width: 100%;
  background-color: $header-background;
  margin-top: 5px;
}

.product-details {
  display: flex;
  width: 85%;
}

.link {
  font-weight: bold;
  text-decoration: none;
  margin-top: 0px;
  font-size: 30px;
  color: #000;
}

.toggle-header {
  padding: 8px;
  height: 32px;
  width: 32px;
}

.sidenav-toggle-icon {
  height: $image-dimension;
  width: $image-dimension;
}

.product-image {
  height: $image-dimension;
}

.user {
  min-width: 600px;
  display: contents;
  // width: 20%;
  // text-align: end;
  // display: inline-block;
}

.user-details {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  margin-left: auto;
  margin-right: 32px;
}

.user-icon {
  width: $image-dimension;
  height: $image-dimension;
  font-size: $standard-icon-font-size;
  margin-top: 5px;
}

.name-role {
  font-size: 14px;
  text-align: center;
  height: $image-dimension;
}

.fiscal-year-drop-down {
  padding-left: 5px;
}
