@import "./../../../styles/style.scss";

.summary-main-div  {
    margin-right: auto;
    margin-left: auto;
}
.wrapper {
    height: 75vh;
    width:100%;
    border:1px solid #DDDDDD;
    overflow-y:scroll,
}
.details-list {
   display:flex;
   align-items: center;
   justify-content: center;
}
.button-border-cls { 
    border-radius: $standard-border-radius;
}
.header-div {
    background-color: #f4f3f8;
}

.column-names-div {
    padding:0px 5px 1px 2px;
}

.action-col-div {
    margin-top: $standard-padding;
}

.new-button-div {
    width: 98%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}
