@import "./../../side-nav-bar-style-variables.scss";

.toggle {
  padding: $standard-padding;
  cursor: pointer;
}

.sidenav-toggle-icon {
  height: $image-dimension;
  width: $image-dimension;
}

.sidenav-toggle-button {
  height: $image-dimension;
  width: $image-dimension;
  border: none;
  padding: 0px;
  cursor: pointer;
 // outline-color: white  ;
  //outline: none;
  background: Transparent;
}

.sidenav-toggle-button:focus-visible{
  color: white;
  background-color: black;
  text-decoration: none
}

.sidenav-toggle-button:hover {
  background: Transparent;
}

.toggle-item {
  display: flex;
}
.toggle-item:hover {
  background-color: $sideNavColor !important;
}

.toggle-side-image-container {
  height: $image-dimension;
  width: $sideNavsize-collapse - $sideNavsize-expand;
  padding: $standard-padding;
}

.toggle-side-image {
  height: $image-dimension;
  //width: $sideNavsize-collapse - $sideNavsize-expand - 2 * $standard-padding;
}

.nav-bar-header-text{
  font-weight: bold;
  text-decoration: none;
  margin-top: 0px;
  font-size: 20px;
  color: white;
}