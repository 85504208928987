@import "./../side-nav-bar-style-variables.scss";


.nav-item:hover {
  background-color: $hover-color;
  .ms-Button {
    color: $nav-image-color;
  }
}

.nav-image {
  height: $image-dimension;
  width: $image-dimension;
  padding: $standard-padding;
}

.nav-image {
  .ms-Button {
    background-color: transparent;
    color: white;
  }
}

.nav-image-container {
  position: relative;
  display: table-cell;
  width: $nav-image-container-width;
  text-align: center;
  vertical-align: top;
  max-height: $image-dimension + 2 * $standard-padding;
}

.nav-data {
  padding: $standard-padding;
  height: $image-dimension;
  color: $text-color;
}
